<template>
  <div>
    <div class="mx-auto max-w-6xl">
      <div class="px-4 md:px-6">
        <slot />
      </div>
    </div>
    <DevBar />
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/stores/cart';
import { useAuthStore } from '~/stores/auth';
import { useEnabledFeaturesStore } from '~/stores/enabledFeatures';

const cartStore = useCartStore();
const authStore = useAuthStore();
const enabledFeaturesStore = useEnabledFeaturesStore();
const route = useRoute();

const { isAccountsEnabled } = useRuntimeConfig().public.featureFlags;

const { isLoggedIn, user } = storeToRefs(authStore);

useHead({
  ...{
    bodyAttrs: {
      class: 'bg-background-extra-light md:bg-background-light',
    },
  },
});

// Login/get user logic since we handle this in the default layout and this layout is not using the default layout
if (isLoggedIn.value && !user.value) {
  try {
    await authStore.getUser();
  } catch {}
}
// In the susbscriptions flow the user can come straight to the checkout without being logged in before, and is being logged in in this layout
else if (
  isAccountsEnabled &&
  enabledFeaturesStore.isFeatureEnabled('subscriptions')
) {
  try {
    await authStore.tryLogin();
    if (isLoggedIn.value && !user.value) {
      await authStore.getUser();
    }
  } catch {}
}

onMounted(async () => {
  const routeName = route?.name?.toString();
  if (
    !routeName?.includes('receipt') &&
    !routeName?.includes('recommended-products')
  ) {
    // Do not refetch cart on receipt page since this causes the finished cart to be restored in localstorage
    // Skip fetching on recommended products page since we want to be able to await it there
    await cartStore.getCart();
  }
});
</script>

<style lang="postcss">
body {
  -webkit-perspective: 1000;
  perspective: 1000;
}
</style>
